import { Button, Close } from 'components/Buttons'
import { FormSection } from 'components/Form'
import { DatePickerWithError } from 'components/FormInputs/DatePicker'
import { PageHeader } from 'components/PageHeader'
import { ReadResult } from 'interfaces/queryOptions'
import { Plan, Client, UsageType } from 'models'
import { ClientSearch, PlanSearch } from 'modules/Orders/components/Searchs'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { Errors } from 'types'
import { addDays, getFirstDayOfMonth } from 'utils/datetime'
import { createSubscription } from 'modules/Clients/services/create'
import { useHistory } from 'react-router-dom'
import {
  InputWithAddon,
  InputWithValidationError,
  RadioListWithDescription,
  SimpleCustomSelect
} from 'components/FormInputs'
import { amount, cleanAmount, currency } from 'utils'
import { validateCreation as validate } from 'modules/Orders/validation'
import { CreateOrderAttributes } from 'modules/Orders/types'
import { fineModes, yesOrNo } from 'components/FormInputs/options'
import { CouponSearch } from 'modules/Orders/components/Searchs/Coupon'
import { applyDiscount } from 'modules/common/utils'
import { list } from 'modules/AccessPlans/services/read'

export function Create() {
  const history = useHistory()

  const [orderedAt, setOrderedAt] = useState<Date | null>(new Date())

  const [accessPlan, setAccessPlan] = useState<ReadResult<Plan>>()
  const [trialPlan, setTrialPlan] = useState<ReadResult<Plan>>()
  const [accessPlanTrialUntil, setAccessPlanTrialUntil] = useState<Date | null>(
    null
  )

  const [client, setClient] = useState<ReadResult<Client>>()

  const [isSaving, setIsSaving] = useState(false)
  const [shouldClear, setShouldClear] = useState(false)
  const [errors, setErrors] = useState<Errors>({})

  const [formData, setFormData] = useState<Record<string, any>>({
    discount: '',
    quantity: 1,
    minimum_invoice_amount: '0,00',
    fine_mode: 'fixed',
    fine_amount: '0,00',
    coupon_id: null,
    trial_end_at: new Date()
  })

  const [trial, setTrial] = useState('no')

  const onSave = useCallback(async () => {
    if (!client || !accessPlan || !orderedAt) return

    setIsSaving(true)
    try {
      await createSubscription(
        client.id,
        accessPlan.id,
        formData.coupon_id,
        {
          quantity:
            accessPlan.attributes.usage_type !== UsageType.licensed
              ? 1
              : formData.quantity,
          ordered_at: orderedAt,
          discount: formData.discount ? cleanAmount(formData.discount) : null,
          minimum_invoice_amount: formData.minimum_invoice_amount
            ? cleanAmount(formData.minimum_invoice_amount)
            : null,
          fine_mode:
            formData.fine_mode && formData.fine_amount !== '0,00'
              ? formData.fine_mode
              : 'fixed',
          fine_amount:
            formData.fine_mode && formData.fine_amount !== '0,00'
              ? cleanAmount(formData.fine_amount)
              : 0
        },
        trial === 'yes' ? true : false,
        formData.trial_end_at
      )

      toast.success('Assinatura criada com sucesso')
      setIsSaving(false)
      history.push('/subscriptions')
    } catch (err: any) {
      setIsSaving(false)
      toast.error(
        err.suggestedMessage ?? 'Falha ao tentar salvar os dados da assinatura'
      )
    }
  }, [client, accessPlan, createSubscription, orderedAt, formData])

  const onAttributeChange = useCallback(
    (attr: keyof CreateOrderAttributes, value: any): void => {
      const error = validate(attr as any, value)

      if (attr === 'trial') {
        setTrial(value)
        if (value === 'no') {
          setFormData((prevFormData) => ({
            ...prevFormData,
            trial_end_at: new Date()
          }))
        }
        return
      }

      setFormData((prevFormData) => {
        if (
          attr === 'fine_mode' ||
          attr === 'quantity' ||
          attr === 'trial_end_at'
        ) {
          return { ...prevFormData, [attr]: value }
        } else {
          return {
            ...prevFormData,
            [attr]: amount(value.replace(/[^0-9]/g, ''))
          }
        }
      })

      setErrors((prevErrors) => ({ ...prevErrors, [attr]: error }))
    },
    []
  )

  const onPlanSelection = useCallback(
    async (plan: any): Promise<void> => {
      setAccessPlan(plan)

      const trial = await list({
        attributes: ['meta'],
        filters: [
          { key: 'usage_type', op: 'eq', value: 'trial' },
          { key: 'currency', op: 'eq', value: plan.attributes.currency },
          { key: 'country_id', op: 'eq', value: plan.relationships.country.id }
        ],
        limit: 1
      })

      setTrial('no')

      if (trial.count !== 0) {
        setTrialPlan(trial.data[0])
        setAccessPlanTrialUntil(
          addDays(
            orderedAt!,
            trial.data[0].attributes.meta?.restriction?.max_length_in_days ?? 60
          )
        )
      } else {
        setAccessPlanTrialUntil(null)
      }

      setFormData({
        quantity: 1,
        discount: '',
        minimum_invoice_amount: amount(plan.attributes.minimum_invoice_amount),
        fine_mode: plan.attributes.fine_mode,
        fine_amount: amount(plan.attributes.fine_amount),
        trial_end_at: new Date()
      })
    },
    [setAccessPlanTrialUntil]
  )

  const onCouponSelection = useCallback((coupon: any): void => {
    setFormData((prevFormData) => {
      return { ...prevFormData, coupon_id: coupon ? coupon.id : null }
    })
  }, [])

  const handleOrderedAtChange = useCallback(
    (value: Date | null): void => {
      setOrderedAt(value)
      if (trialPlan) {
        const maxTrialDate = addDays(
          value!,
          trialPlan?.attributes.meta?.restriction?.max_length_in_days ?? 60
        )
        setAccessPlanTrialUntil(maxTrialDate)

        if (accessPlanTrialUntil! > maxTrialDate) {
          setFormData({ ...formData, trial_end_at: maxTrialDate })
        }
      }
    },
    [accessPlanTrialUntil, trialPlan]
  )

  return (
    <>
      <PageHeader title="Assinaturas" action="Nova" />

      <div className="flex flex-row space-x-10 px-4 animate-fade-in-down">
        <div className="w-1/2">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Cliente">
              <ClientSearch
                onSelect={setClient}
                error={errors.client}
                planCountryId={accessPlan?.relationships?.country.id}
              />
            </FormSection>

            <FormSection title="Acesso">
              <PlanSearch
                clear={shouldClear}
                setClear={setShouldClear}
                onSelect={onPlanSelection}
                onChange={setAccessPlan}
                error={errors.plan}
                clientCountryId={client?.relationships?.country.id}
                subscription={true}
              />
            </FormSection>

            <FormSection title="Assinatura">
              <div className="w-1/2">
                <DatePickerWithError
                  label="Pedido em"
                  name="orderedAt"
                  date={orderedAt}
                  minDate={getFirstDayOfMonth()}
                  maxDate={new Date()}
                  onChange={handleOrderedAtChange}
                />
              </div>

              {accessPlan && accessPlanTrialUntil && (
                <div className="w-1/6">
                  <RadioListWithDescription
                    label="Teste"
                    options={yesOrNo}
                    value={trial}
                    onChange={(value) => onAttributeChange('trial', value)}
                  />
                </div>
              )}
              {trial === 'yes' && (
                <>
                  <p className="text-rose-700">
                    Atenção: o Plano será ativado no dia seguinte à data de
                    encerramento do teste
                  </p>
                  <DatePickerWithError
                    label="Data de encerramento do teste"
                    name="trial_end_at"
                    date={formData.trial_end_at}
                    onChange={(value) =>
                      onAttributeChange(
                        'trial_end_at',
                        new Date(value!) ?? new Date()
                      )
                    }
                    minDate={orderedAt}
                    maxDate={accessPlanTrialUntil}
                  />
                </>
              )}

              {accessPlan && accessPlan.attributes.usage_type === 'licensed' && (
                <div className="flex justify-between gap-8">
                  <div className="w-1/2">
                    <InputWithValidationError
                      label="Quantidade"
                      type="number"
                      name="quantity"
                      min="1"
                      value={formData?.quantity ?? 1}
                      onChange={(value) => onAttributeChange('quantity', value)}
                      error={errors.quantity}
                      onWheel={(e: React.FocusEvent<HTMLInputElement>) =>
                        e.target.blur()
                      }
                    />
                  </div>
                </div>
              )}

              {(accessPlan?.attributes.usage_type === 'metered' ||
                accessPlan?.attributes.usage_type === 'licensed') && (
                <div className="flex justify-between gap-8">
                  <div className="w-1/2">
                    <InputWithAddon
                      label="Desconto"
                      type="text"
                      frontAddon={currency(accessPlan?.attributes.currency)}
                      disabled={accessPlan?.attributes.restricted}
                      name="discount"
                      value={formData.discount}
                      onChange={(value) => onAttributeChange('discount', value)}
                    />
                  </div>
                  <div className="w-1/2">
                    <label className="block text-left text-sm font-medium text-gray-700">
                      Valor com desconto
                    </label>
                    <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                      {accessPlan
                        ? `${currency(accessPlan.attributes.currency)} ${amount(
                            applyDiscount(
                              accessPlan.attributes.amount as number,
                              +formData.discount.replace(',', '.') * 100
                            )
                          )}`
                        : ''}
                    </div>
                  </div>
                </div>
              )}

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <SimpleCustomSelect
                    label="Tipo de multa"
                    options={fineModes}
                    value={formData?.fine_mode}
                    onChange={(value) => onAttributeChange('fine_mode', value)}
                  />
                </div>
                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor da multa (por unidade)"
                    name="fine_amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={formData?.fine_amount}
                    frontAddon={
                      formData.fine_mode === 'fixed'
                        ? currency(accessPlan?.attributes.currency)
                        : '%'
                    }
                    onChange={(value) =>
                      onAttributeChange('fine_amount', value)
                    }
                    error={errors.amount}
                  />
                </div>
              </div>

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor mínimo da fatura"
                    name="minimum_invoice_amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={formData.minimum_invoice_amount}
                    frontAddon={currency(accessPlan?.attributes.currency)}
                    onChange={(value) =>
                      onAttributeChange('minimum_invoice_amount', value)
                    }
                    error={errors.minimum_invoice_amount}
                  />
                </div>
              </div>
            </FormSection>

            {accessPlan && (
              <FormSection title="Cupom">
                <CouponSearch
                  clear={shouldClear}
                  setClear={setShouldClear}
                  onSelect={onCouponSelection}
                  error={errors.coupon}
                  plan={accessPlan}
                />
              </FormSection>
            )}
          </form>

          <div className="px-4 mt-8 flex space-x-4 justify-end animate-fade-in-down">
            <Close linkTo="/subscriptions" />

            <Button
              disabled={isSaving}
              type="submit"
              onClick={onSave}
              label="Assinar"
            />
          </div>
        </div>
      </div>
    </>
  )
}
