import { ReadResult } from 'interfaces/queryOptions'
import {
  Client,
  Order,
  OrderConnectivityStatus,
  OrderHardwareStatus,
  OrderStatus,
  Plan,
  Role
} from 'models'
import { FiscalData } from 'models/orderItem'
import { edit as baseEdit, patch } from 'services/billing'
import { checkRoles, cleanAmount } from 'utils'
import { ItemFiscalData } from '../types'
import { Coupon } from 'models/coupon'

export type EditOrder = {
  order: ReadResult<Order>
  client: ReadResult<Client>
  plan: ReadResult<Plan>
  coupon?: ReadResult<Coupon>
}

const transformAttributes = (
  attributes: Record<string, any>
): Record<string, any> => {
  const { fine_mode, fine_amount } = attributes

  if (!fine_amount) {
    return {
      fine_mode,
      fine_amount
    }
  }

  return {
    fine_mode: fine_amount === '' || fine_amount === 0 ? 'fixed' : fine_mode,
    fine_amount:
      fine_amount === '' ? 0 : fine_mode === null ? 0 : cleanAmount(fine_amount)
  }
}

export async function edit(
  id: number,
  { order, client, plan, coupon }: EditOrder
): Promise<ReadResult<Order>> {
  const { fine_mode, fine_amount } = transformAttributes(order.attributes)

  const payload = {
    attributes: {
      quantity: order.attributes.quantity,
      discount: order.attributes.discount,
      shipping_method: order.attributes.shipping_method,
      ordered_at: order.attributes.ordered_at,
      fine_mode,
      fine_amount
    },
    relationships: {
      client: {
        type: 'client',
        id: client.id
      },
      plan: {
        type: 'plan',
        id: plan.id
      },
      coupon: {
        type: 'coupon',
        id: null as unknown as number
      }
    }
  }

  if (coupon) {
    payload.relationships.coupon = {
      type: 'coupon',
      id: coupon.id
    }
  }

  const response = await baseEdit<Order>('/orders', id, payload)
  return response
}

export async function split(id: number, quantities: number[]): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])
  await patch(`/orders/${id}/split`, { data: { quantities } })
}

export async function updateStatus(
  id: number | string,
  status: OrderStatus,
  statusUpdatedAt: Date
): Promise<any> {
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  const { data } = await patch(`/orders/${id}/update-status/${status}`, {
    data: { status_updated_at: statusUpdatedAt }
  })
  return data
}

export async function approve(
  id: number | string,
  approvedAt: Date
): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const { data } = await patch(`/orders/${id}/approve`, {
    data: { status_updated_at: approvedAt }
  })
  return data
}

export async function cancel(
  id: number | string,
  fiscalData?: Partial<FiscalData>,
  canceledAt?: Date
): Promise<any> {
  await checkRoles([Role.MANAGER, Role.FINANCE])
  const attributes = {
    ...fiscalData,
    ...(canceledAt && { canceled_at: canceledAt })
  }
  const payload = Object.keys(attributes).length > 0 ? { attributes } : {}
  const { data } = await patch(`/orders/${id}/cancel`, { data: payload })
  return data
}

export async function deliver(
  id: number | string,
  deliveredAt: Date
): Promise<any> {
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  const { data } = await patch(`/orders/${id}/deliver`, {
    data: { status_updated_at: deliveredAt }
  })
  return data
}

export async function updateItemFiscalData(
  orderId: number,
  data: ItemFiscalData
): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])
  await patch(`/orders/${orderId}/items/fiscal-data`, { data })
}

export async function updateItemStatus(
  orderId: number,
  status: OrderHardwareStatus | OrderConnectivityStatus,
  data: ItemFiscalData[]
): Promise<void> {
  await checkRoles([Role.MANAGER, Role.FINANCE, Role.LOGISTICS])

  const dataWithStatus = data.map((d) => {
    return { attributes: { ...d.attributes, status } }
  })

  await patch(`/orders/${orderId}/items/update-status`, {
    data: dataWithStatus
  })
}
