import { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'

import { classNames } from 'utils'

interface Option {
  value: number | string | undefined
  label: string
}

interface SimpleCustomSelectProps {
  options: Option[]
  onChange: (value: any) => any
  label?: string
  value?: number | string
  [key: string]: any
}

export function SimpleCustomSelect({
  label,
  options,
  onChange,
  value,
  ...rest
}: SimpleCustomSelectProps) {
  const selected = options.find((option) => option.value === value) ?? {
    value: '',
    label: ''
  }

  return (
    <Listbox
      value={selected}
      onChange={(option) => onChange(option.value)}
      {...rest}
    >
      {({ open }) => (
        <>
          <div className="">
            <Listbox.Label className="block text-left text-sm font-medium text-gray-700">
              {label}
            </Listbox.Label>
            <div className="mt-1 relative shadow-sm">
              <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:border-emerald-500">
                <span className="block h-6 truncate">{selected.label}</span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon
                    className="h-5 w-5 bg-white text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none">
                  {options.map((option) => (
                    <Listbox.Option
                      key={option.value}
                      className={({ active }) =>
                        classNames(
                          active
                            ? 'text-white bg-emerald-600'
                            : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={option}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {option.label}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-emerald-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        </>
      )}
    </Listbox>
  )
}
