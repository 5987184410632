/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
export enum PlanType {
  connectivity = 'connectivity',
  hardware = 'hardware',
  software = 'software'
}

export enum TierMode {
  volume = 'volume',
  discount = 'discount',
  graduated = 'graduated',
  package = 'package'
}

export interface Tier {
  unit_amount: number
  up_to: number
}

export enum BillingScheme {
  per_unit = 'per_unit',
  tiered = 'tiered'
}

export enum Subtype {
  claro = 'Claro',
  vivo = 'Vivo',
  tim = 'Tim',
  algar = 'Algar',
  arqia = 'Arqia',
  suntech_st310_u = 'suntech_st310_u',
  suntech_st310_uc2 = 'suntech_st310_uc2',
  suntech_300hd = 'suntech_300hd',
  cr_4000_a = 'cr_4000_a',
  mt_2000 = 'mt_2000',
  nt20 = 'nt20',
  nt40 = 'nt40',
  satelital = 'globalstar_smartone_c',
  y202 = 'y202',
  j16 = 'j16',
  y18 = 'y18',
  j14 = 'j14',
  queclink_gv50 = 'queclink_gv50',
  ev02 = 'ev02',
  suntech_st310_ulc = 'suntech_st310_ulc',
  e4_mini = 'e4-mini'
}

export enum Nature {
  good = 'good',
  service = 'service'
}

export enum Currency {
  brl = 'brl',
  usd = 'usd',
  uyi = 'uyi',
  eur = 'eur',
  ars = 'ars'
}

export enum Interval {
  day = 'day',
  week = 'week',
  month = 'month',
  year = 'year'
}

export enum UsageType {
  tiered = 'tiered',
  licensed = 'licensed',
  metered = 'metered'
}

export enum BillingType {
  prepaid = 'prepaid',
  postpaid = 'postpaid'
}

export interface PlanMeta {
  [key: string]: any
}

export interface Plan {
  id: number
  name: string
  active: boolean
  type: PlanType
  meta: PlanMeta
  nature: Nature
  amount?: number | string
  currency: Currency
  interval: Interval
  duration: number
  interval_count: number
  usage_type: UsageType
  billing_type: BillingType
  renewable: boolean
  prorata: boolean
  restricted: boolean
  guarantors: boolean
  logistics: boolean
  fiscal_data: boolean
  description?: string
  country_id: number
  created_at: Date
  updated_at: Date
  deleted_at?: Date
  minimum_invoice_amount: number | string
  fine_mode: string
  fine_amount: number | string
  tiers: Tier[]
  tier_mode: string
  relationships?: Record<string, any>
}
