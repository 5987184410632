import { Client, Order, Plan, Role } from 'models'
import { checkRoles, isNil, omitBy } from 'utils'
import { create as baseCreate, post } from 'services/billing'
import { ReadResult } from 'interfaces/queryOptions'
import { Coupon } from 'models/coupon'

export interface Cart {
  order: Partial<Order>
  plan: ReadResult<Plan>
  coupon?: Partial<Coupon>
}

type OrderPayload = {
  attributes: Partial<Order>
  relationships: {
    client: {
      type: 'client'
      id: number
    }
    plan: {
      type: 'plan'
      id: number
    }
    coupon?: {
      type: 'coupon'
      id: number
    }
  }
}

export async function create(
  cart: Cart[],
  client: ReadResult<Client>
): Promise<void> {
  await checkRoles([Role.MANAGER, Role.SUPPORT, Role.SALES])

  const payload: OrderPayload[] = []

  for (const item of cart) {
    const entry: OrderPayload = {
      attributes: { ...item.order },
      relationships: {
        client: { type: 'client', id: client.id },
        plan: { type: 'plan', id: item.plan.id }
      }
    }

    if (item.coupon) {
      entry.relationships.coupon = {
        type: 'coupon',
        id: item.coupon.id as number
      }
    }

    if (entry.attributes.fine_amount === 0) {
      entry.attributes.fine_mode = 'fixed'
    }

    entry.attributes = {
      ...omitBy(entry.attributes, isNil)
    }
    payload.push(entry)
  }

  await baseCreate<Order>('/orders', payload)
}

export async function addItems(
  orderId: number,
  itemIds: number[]
): Promise<void> {
  await checkRoles([Role.MANAGER, Role.LOGISTICS])
  await post(`/orders/${orderId}/items`, { item_ids: itemIds })
}
