import { Modal } from 'components/Modal'
import { User } from 'models'
import { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { loginAs } from '../services'

const appBaseUrl =
  process.env.REACT_APP_ENV === 'production'
    ? 'https://app.softruck.com'
    : 'https://app.apiary.softruck.com'

interface Props {
  open: boolean
  user: Partial<User>
  setOpen: (open: boolean) => any
}

export function LoginAsModal({ open, setOpen, user }: Props) {
  const [isLoading, setIsLoading] = useState(false)

  const onConfirm = useCallback(async () => {
    if (!user) return

    setIsLoading(true)

    try {
      const { token } = await loginAs(user.id!.toString())
      setIsLoading(false)

      window.open(`${appBaseUrl}/access/external?token=${token}`, '_blank')
    } catch (err: any) {
      setIsLoading(false)
      toast.error(err.suggestedMessage ?? 'Falha ao fazer login como cliente')
      return
    }

    setOpen(false)
  }, [user, setOpen])

  return (
    <>
      {user && user.attributes && (
        <Modal
          open={open}
          title={'Login como Cliente'}
          onClose={() => setOpen(false)}
          width="sm:w-1/2"
        >
          <div className="mt-5">
            <div className="max-w-xl text-md text-gray-600">
              <p>Confirme o login do usuário abaixo:</p>
            </div>
            <div className="mt-2 rounded-md bg-gray-50 px-6 py-5 sm:flex sm:items-start sm:justify-between">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 sm:mt-0">
                  <div className="text-md font-medium text-gray-900">
                    {user.attributes.name}
                  </div>
                  <div className="mt-1 text-sm text-gray-600 sm:flex sm:items-center">
                    <div className="mt-1 sm:mt-0">
                      {user.attributes.username}
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-6 sm:flex-shrink-0">
                <button
                  disabled={isLoading}
                  type="button"
                  onClick={onConfirm}
                  className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none sm:text-sm"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}
