import { toast } from 'react-toastify'
import { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  InputWithAddon,
  ResizibleTextArea,
  SimpleCustomSelect,
  CheckboxWithDescription,
  InputWithValidationError,
  RadioListWithDescription,
  InlineList
} from 'components/FormInputs'
import { FormSection } from 'components/Form'
import { PageHeader } from 'components/PageHeader'
import {
  billingTypes,
  currencies,
  intervals,
  natures,
  planTypes,
  serviceProviders,
  deviceTypes,
  usageTypes,
  fineModes
} from 'components/FormInputs/options'

import { Plan, PlanMeta, PlanType } from 'models'
import { Errors } from 'types'
import { currency, formatCurrency } from 'utils'
import { check } from 'utils/error'
import { validate } from '../validation'
import { create } from '../services/api'
import { cleanAmount } from 'utils'
import { listCountries } from 'modules/Staff/services/api'

export function Create() {
  const history = useHistory()

  interface CountryOption {
    value: number
    label: string
  }

  const [countries, setCountries] = useState<CountryOption[]>([])
  const [selectedCountry, setSelectedCountry] = useState('')

  useEffect(() => {
    const fetchCountries = async () => {
      const countries = await listCountries({
        attributes: ['code', 'name', 'local_name']
      })

      setCountries(
        countries.data.map((country) => {
          return {
            value: country.id,
            label: country.attributes.local_name ?? ''
          }
        })
      )
    }

    fetchCountries()
  }, [])

  const [plan, setPlan] = useState<Record<string, any>>({
    type: planTypes[0].value,
    amount: 0,
    active: false,
    currency: currencies[0].value,
    billing_type: billingTypes[0].value,
    interval: intervals[0].value,
    interval_count: 1,
    duration: 1,
    renewable: false,
    restricted: false,
    guarantors: false,
    logistics: true,
    fiscal_data: false,
    prorata: true,
    nature: natures[0].value,
    usage_type: usageTypes[0].value,
    minimum_invoice_amount: '0,00',
    fine_mode: 'fixed',
    fine_amount: '0,00',
    tier_mode: null,
    tiers: null
  })

  const [planMeta, setPlanMeta] = useState(deviceTypes[0].value)
  const [planMetaOptions, setPlanMetaOptions] = useState(deviceTypes)

  const [errors, setErrors] = useState<Errors>({})

  const validateFieds = useCallback(
    (plan: Record<string, any>): boolean => {
      const isNameFilled = !!(
        Object.prototype.hasOwnProperty.call(plan, 'name') && plan.name !== null
      )
      const isAmountFilled = !!(
        Object.prototype.hasOwnProperty.call(plan, 'amount') &&
        plan.amount !== 0
      )

      if (!isNameFilled || !isAmountFilled) {
        setErrors({
          ...errors,
          name: !isNameFilled ? 'O campo deve ser preenchido' : null,
          amount: !isAmountFilled ? 'Valor inválido' : null
        })

        return true
      }
      return false
    },
    [errors]
  )

  const onSave = useCallback(async () => {
    if (!plan) return

    if (!selectedCountry) {
      toast.error('O país do plano deve ser selecionado')
      return
    }

    const transformAttributes = (
      attributes: Record<string, any>
    ): Record<string, any> => {
      const {
        minimum_invoice_amount,
        fine_mode,
        fine_amount,
        tier_mode,
        tiers
      } = attributes

      return {
        minimum_invoice_amount:
          minimum_invoice_amount === ''
            ? null
            : cleanAmount(minimum_invoice_amount),
        fine_mode: fine_amount === '' ? 'fixed' : fine_mode,
        fine_amount:
          fine_amount === ''
            ? 0
            : fine_mode === null
            ? 0
            : cleanAmount(fine_amount),
        tier_mode:
          tiers === null ? null : tiers?.length === 0 ? null : tier_mode,
        tiers:
          tier_mode === null || (tiers && tiers.length === 0) ? null : tiers
      }
    }

    if (validateFieds(plan) || check(errors)) {
      return
    }

    const otherAttributes = transformAttributes(plan)

    try {
      await create(
        { ...plan, amount: cleanAmount(plan.amount), ...otherAttributes },
        planMeta as string,
        selectedCountry
      )
      toast.success('Plano criado com sucesso')
      history.push('/plans')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Houve uma falha ao tentar criar o plano'
      )
    }
  }, [validateFieds, errors, history, plan, selectedCountry])

  const onAttributeChange = useCallback(
    (attr: keyof Partial<Plan> | keyof Partial<PlanMeta>, value: any): void => {
      if (!plan) return

      const error = validate(attr as any, value)

      if (attr === 'type') {
        if (value === PlanType.hardware) {
          setPlanMeta(deviceTypes[0].value)
          setPlanMetaOptions(deviceTypes)
        } else {
          setPlanMeta(serviceProviders[0].value)
          setPlanMetaOptions(serviceProviders)
        }
      }

      if (attr === 'fine_amount') {
        value = formatCurrency(value)
      }

      if (attr === 'meta') {
        setPlanMeta(value)
      } else {
        setPlan({ ...plan, [attr]: value })
      }

      setErrors({ ...errors, [attr]: error })
    },
    [errors, plan]
  )

  return (
    <>
      <PageHeader title="Planos" action="Novo" />
      <div className="flex flex-row space-x-8">
        <div className="w-3/5 px-4 animate-fade-in-down">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            <FormSection title="Novo plano">
              <InlineList
                label="Tipo de plano"
                description=""
                options={planTypes}
                value={plan?.type}
                onChange={(type) => onAttributeChange('type', type)}
              />

              <SimpleCustomSelect
                label="País"
                options={countries}
                value={selectedCountry}
                onChange={(value) => setSelectedCountry(value)}
              />

              <InputWithValidationError
                label="Nome"
                name="name"
                value={plan?.name}
                onChange={(name) => onAttributeChange('name', name)}
                error={errors.name}
              />

              <ResizibleTextArea
                label="Descrição"
                name="description"
                value={plan?.description}
                onChange={(description) =>
                  onAttributeChange('description', description)
                }
              />

              <fieldset className="space-y-5">
                <legend className="text-left">Estado de disponibilidade</legend>
                <CheckboxWithDescription
                  label="Ativado"
                  name="active"
                  checked={plan?.active}
                  onChange={(active) => onAttributeChange('active', active)}
                  description="Ao ativar o plano, todo o time da Softruck será notificado sobre sua criação ou edição."
                />
              </fieldset>
            </FormSection>

            <FormSection title="Valores e Períodos">
              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <SimpleCustomSelect
                    label="Moeda"
                    options={currencies}
                    value={plan?.currency}
                    onChange={(currency) =>
                      onAttributeChange('currency', currency)
                    }
                  />
                </div>

                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor"
                    name="amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={plan?.amount}
                    frontAddon={currency(plan?.currency)}
                    onChange={(value) => onAttributeChange('amount', value)}
                    error={errors.amount}
                  />
                </div>
              </div>

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <InputWithValidationError
                    label="Duração"
                    optional="meses"
                    name="duration"
                    value={plan?.duration}
                    onChange={(duration) =>
                      onAttributeChange('duration', duration)
                    }
                    error={errors.duration}
                  />
                </div>

                <div className="w-1/3">
                  <SimpleCustomSelect
                    label="Cobrança"
                    options={intervals}
                    value={plan?.interval}
                    onChange={(interval) =>
                      onAttributeChange('interval', interval)
                    }
                  />
                </div>

                <div className="w-1/3">
                  <InputWithValidationError
                    label="Intervalo"
                    name="interval_count"
                    value={plan?.interval_count}
                    onChange={(intervalCount) =>
                      onAttributeChange('interval_count', intervalCount)
                    }
                    error={errors.interval_count}
                  />
                </div>
              </div>

              <RadioListWithDescription
                label="Modo de Fatura"
                options={billingTypes}
                value={plan?.billing_type}
                onChange={(billingType) =>
                  onAttributeChange('billing_type', billingType)
                }
              />

              <div className="flex flex-row space-x-8">
                <div className="w-1/3">
                  <SimpleCustomSelect
                    label="Tipo de multa"
                    options={fineModes}
                    value={plan?.fine_mode}
                    onChange={(fineModes) =>
                      onAttributeChange('fine_mode', fineModes)
                    }
                  />
                </div>

                <div className="w-1/3">
                  <InputWithAddon
                    label="Valor da multa (por unidade)"
                    name="fine_amount"
                    type="text"
                    pattern="[0-9]+([\.,][0-9]+)?"
                    value={plan?.fine_amount}
                    frontAddon={
                      plan.fine_mode === 'fixed'
                        ? currency(plan?.currency)
                        : '%'
                    }
                    onChange={(value) =>
                      onAttributeChange('fine_amount', value)
                    }
                    error={errors.amount}
                  />
                </div>
              </div>
            </FormSection>

            <FormSection title="Restrições">
              <fieldset className="space-y-5">
                <legend className="text-left">Extensão de prazos</legend>
                <CheckboxWithDescription
                  label="Renovável"
                  name="renewable"
                  checked={plan?.renewable}
                  onChange={(renewable) =>
                    onAttributeChange('renewable', renewable)
                  }
                  description="Se o plano permite renovação este campo deverá ser ativado, lembrando que comodatos de rastreadores não devem ter essa condição como ativada"
                />
              </fieldset>

              <fieldset className="space-y-5">
                <legend className="text-left">Aplicação de descontos</legend>
                <CheckboxWithDescription
                  label="Restrito"
                  name="restricted"
                  checked={plan?.restricted}
                  onChange={(restricted) =>
                    onAttributeChange('restricted', restricted)
                  }
                  description="Planos restritos não podem ser aplicados nenhum tipo de desconto"
                />
              </fieldset>

              <fieldset className="space-y-5">
                <legend className="text-left">Definição de garantias</legend>
                <CheckboxWithDescription
                  label="Devedor solidário"
                  name="guarantors"
                  checked={plan?.guarantors}
                  onChange={(guarantors) =>
                    onAttributeChange('guarantors', guarantors)
                  }
                  description="Planos de hardware em sua maioria necessitam de uma segurança financeira para serem vendidos"
                />
              </fieldset>

              <fieldset className="space-y-5">
                <legend className="text-left">Definições Logísticas</legend>
                <CheckboxWithDescription
                  disabled
                  label="Necessita de suporte logístico"
                  name="logistics"
                  checked={plan?.logistics}
                  onChange={(logistics) =>
                    onAttributeChange('logistics', logistics)
                  }
                  description="Escolha esta opção se o plano necessita de etapas de construção internas"
                />
              </fieldset>

              <fieldset className="space-y-5">
                <legend className="text-left">Dados fiscais</legend>
                <CheckboxWithDescription
                  label="Necessita de dados fiscais"
                  name="fiscal_data"
                  checked={plan?.fiscal_data}
                  onChange={(fiscalData) =>
                    onAttributeChange('fiscal_data', fiscalData)
                  }
                  description="Escolha esta opção se o plano necessita de etapas de formalizações fiscais"
                />
              </fieldset>
            </FormSection>

            <FormSection title="Estruturas de uso">
              <RadioListWithDescription
                label="Natureza"
                options={natures}
                value={plan.nature}
                onChange={(nature) => onAttributeChange('nature', nature)}
              />

              <SimpleCustomSelect
                label="Restrição"
                options={planMetaOptions}
                value={planMeta}
                onChange={(meta) => onAttributeChange('meta', meta)}
              />

              <RadioListWithDescription
                label="Tipo do plano"
                options={usageTypes}
                value={plan?.usage_type}
                onChange={(usageType) =>
                  onAttributeChange('usage_type', usageType)
                }
              />
            </FormSection>

            <div className="mt-12 flex justify-end">
              <button
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
                onClick={() => history.goBack()}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-emerald-600 hover:bg-emerald-700 focus:outline-none"
              >
                Salvar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}
