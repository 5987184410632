import { useEffect } from 'react'

import { billingCycle, phone } from 'utils/presentation'
import { translations } from 'modules/Clients/translations'
import { useViewClient } from 'modules/Clients/hooks/useViewClient'
import { IdentificationTable } from 'modules/Clients/components/Identification'

export function OverallTab() {
  const {
    client,
    fetchClient,
    isRefreshing,
    identification,
    refreshCorporateData
  } = useViewClient()

  useEffect(() => {
    if (!client) {
      fetchClient()
    }
  }, [client, fetchClient])

  return (
    <div className="flex flex-row justify-between py-8 animate-fade-in-down">
      <div className="w-1/2">
        <div className="">
          <div className="w-full">
            <div>
              <h3 className="ml-4 text-xl leading-6 font-medium text-gray-900">
                Informações gerais
              </h3>
            </div>
            <div className="mt-5 border-t border-gray-200 animate-fade-in-down">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    E-mail
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {client?.attributes.email}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Telefone
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {phone(client?.attributes.phones?.[0].phone.slice(2))}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Tipo
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {translations['type'][client?.attributes.type as string]}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Segmento
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      translations['segment'][
                        client?.attributes.segment as string
                      ]
                    }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    País
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {
                      translations['country'][
                        client?.relationships?.country?.attributes
                          ?.name as string
                      ]
                    }
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Próximo vencimento
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {billingCycle(client?.attributes.billing_cycle_day)}
                  </dd>
                </div>
                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                  <dt className="ml-4 text-sm font-medium text-gray-500">
                    Descriçao
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {client?.attributes.description}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>

      {identification && identification.document && (
        <IdentificationTable
          refreshing={isRefreshing}
          identification={identification}
          onRefresh={refreshCorporateData}
        />
      )}
    </div>
  )
}
