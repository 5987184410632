import { OrderStatus } from 'models'
import { Step } from '../types'
import { orderBaseSteps } from './constants'

export function includeStatus(
  currentStatus: OrderStatus | undefined,
  validStatus: OrderStatus[]
) {
  if (!currentStatus) return false

  return validStatus.includes(currentStatus)
}

export function formatDiscount(discount: string): number {
  if (!discount) return 0
  return Math.floor(parseFloat(discount) * 100)
}

export function updateSteps(steps: Step[], currentStatus: string): Step[] {
  if (currentStatus === 'canceled') return orderBaseSteps

  for (const step of steps) {
    step.status = 'complete'
    if (step.label === currentStatus) {
      currentStatus === 'delivered'
        ? (step.status = 'complete')
        : (step.status = 'current')
      break
    }
  }
  return steps
}

export function isCurrentMonth(date: Date | string | undefined) {
  if (!date) return false

  const currentMonth = new Date().getUTCMonth()
  const orderedMonth = new Date(date).getUTCMonth()

  if (currentMonth === orderedMonth) return true

  return false
}
