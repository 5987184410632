import { useState, useEffect, useCallback, useMemo } from 'react'
import { CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import Downshift from 'downshift'
import debounce from 'lodash.debounce'

import config from 'config'
import { list } from '../../../Clients/services/read'
import { translations } from '../../translations'
import { document } from 'utils'
import { ListResult, ReadResult } from 'interfaces/queryOptions'
import { Client } from 'models'

interface Props {
  onSelect: (value: any) => any
  value?: ReadResult<Client>
  [key: string]: any
}

export function ClientSearch({
  onSelect,
  error,
  value,
  planCountryId,
  ...rest
}: Props) {
  const [isLoading, setIsLoading] = useState(false)
  const [search, setSearch] = useState('')

  const [clients, setClients] = useState<ListResult<Client>>()
  const [client, setClient] = useState<ReadResult<Client>>()
  const [errors, setErrors] = useState(error)

  const onSearch = useMemo(
    () =>
      debounce(async (search: string) => {
        if (search.length < config.min_search_length) return

        setIsLoading(true)
        try {
          const clientsUnwantedStatuses = [
            'canceled',
            'not_renewed',
            'restricted'
          ]

          const baseFilters = [
            {
              key: 'statuses.status',
              op: 'nin',
              value: clientsUnwantedStatuses
            }
          ]
          const filters = planCountryId
            ? [
                ...baseFilters,
                { key: 'country_id', op: 'eq', value: planCountryId }
              ]
            : baseFilters

          const result = await list('/clients', {
            includes: { statuses: ['status'], country: ['*'] },
            attributes: ['id', 'account_name', 'document', 'document_type'],
            filters,
            search
          })

          setClients(result)
          setIsLoading(false)
        } catch (err: any) {
          setIsLoading(false)
          console.error(err)
        }
      }, config.autocomplete_debounce_rate),
    [planCountryId]
  )

  useEffect(() => {
    if (!value) return

    setClient(value)
    setSearch(value.attributes.account_name ?? '')
  }, [value])

  useEffect(() => {
    if (search === '') return

    if (search === client?.attributes.account_name) return

    setErrors('')

    onSearch(search)
  }, [search, errors, client, onSearch])

  const onClick = useCallback(
    (index: number) => {
      if (!clients) return

      onSelect(clients.data[index])
      setClient(clients.data[index])
      setSearch(clients.data[index].attributes.account_name ?? '')
    },
    [clients]
  )

  return (
    <div>
      <Downshift
        onChange={(selection) => (selection ? onSelect(client) : undefined)}
        itemToString={(item) => item?.attributes.account_name ?? ''}
        selectedItem={client || { attributes: { account_name: '' } }}
      >
        {({ getInputProps, getItemProps, isOpen }) => (
          <div className="relative">
            <label className="block text-left text-sm font-medium text-gray-700">
              Conta
            </label>
            <div className="relative flex items-center">
              <input
                className={
                  'mt-1 block w-full rounded-md shadow-sm py-2 px-3 border focus:outline-none ' +
                  (error
                    ? 'border-red-500 focus:border-red-500'
                    : 'border-gray-300 focus:border-emerald-500')
                }
                {...getInputProps({
                  value: search || '',
                  onChange: (e) => {
                    setSearch(e.currentTarget.value)
                  },
                  ...rest
                })}
              />
              {isLoading && (
                <div className="absolute right-1">
                  <svg
                    className="animate-spin mt-1 mr-3 h-5 w-5 text-emerald-700"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                </div>
              )}
              {errors && (
                <div className="absolute inset-y-0 right-0 pt-1 pr-3 flex items-center pointer-events-none">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {errors && (
              <p className="absolute left-0 text-xs text-red-500">{errors}</p>
            )}
            <ul className="w-full absolute mt-2 rounded-md bg-white z-20 shadow flex flex-col">
              {isOpen && clients && clients.data.length > 0 && (
                <div className="max-h-96 overflow-auto">
                  {clients.data.map((item, index) => (
                    <div
                      className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-between"
                      key={item.id}
                      {...getItemProps({
                        key: item.id,
                        onClick: () => {
                          onClick(index)
                        },
                        item
                      })}
                    >
                      <div>
                        <p className="text-md text-left text-gray-900">
                          {item.attributes.account_name}
                        </p>
                        <p className="text-sm text-left text-gray-700">
                          {document(
                            item.attributes.document,
                            item.attributes.document_type
                          )}
                        </p>
                      </div>
                      <div id="device-suport">
                        {item.relationships?.statuses?.[0].attributes.status ===
                        'active' ? (
                          <CheckCircleIcon className="w-4 h-4 mt-4 mr-2 text-emerald-500" />
                        ) : (
                          <ExclamationCircleIcon className="w-4 h-4 mt-4 mr-2 text-amber-300" />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
              {isOpen && !clients?.data.length && (
                <li className="px-4 py-2 w-full cursor-pointer bg-white hover:bg-gray-100 flex justify-start">
                  Nenhum resultado encontrado
                </li>
              )}
            </ul>

            <div className="flex pt-4 justify-between gap-8">
              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Documento
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {client && client.id
                    ? document(
                        client.attributes.document,
                        client.attributes.document_type
                      )
                    : ''}
                </div>
              </div>

              <div className="w-1/2">
                <label className="block text-left text-sm font-medium text-gray-700">
                  Status
                </label>
                <div className="mt-1 h-10 w-full text-left font-medium text-gray-600 border rounded-md py-2 px-2 focus:outline-none bg-gray-50">
                  {client && client.id
                    ? translations.client_status[
                        client.relationships?.statuses?.[0].attributes
                          .status as string
                      ]
                    : ''}
                </div>
              </div>
            </div>
          </div>
        )}
      </Downshift>
    </div>
  )
}
