import { amount, currency, date, time } from 'utils'
import { Client, Order, Plan } from 'models'
import { translations } from 'modules/Orders/translations'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { Link } from 'react-router-dom'

interface ReviewTableProps {
  order: Partial<Order>
  client: Partial<Client> | undefined
  plan: Partial<Plan> | undefined
}

export function ReviewTable({ order, client, plan }: ReviewTableProps) {
  return (
    <dl className="rounded shadow mx-4 my-8 animate-fade-in-down">
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Cliente</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <Link
            to={`/clients/${client?.id}/view/general`}
            className="text-gray-900 hover:underline"
          >
            {client?.account_name}
          </Link>
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-900 ml-2"
            aria-hidden="true"
          />
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Acesso</dt>
        <dd className="flex mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <Link
            to={`/access-plans/${plan?.id}/view/general`}
            className="text-gray-900 hover:underline"
          >
            {plan?.name}
          </Link>
          <ExternalLinkIcon
            className="h-5 w-5 text-gray-9000 ml-2"
            aria-hidden="true"
          />
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Desconto</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {order.discount
            ? `${currency(
                order.relationships?.plan.attributes.currency
              )} ${amount(order.discount! as number)}`
            : '-'}
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">
          Valor mínimo da fatura
        </dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {order?.minimum_invoice_amount && order.minimum_invoice_amount !== 0
            ? `${currency(plan?.currency)} ${amount(
                order.minimum_invoice_amount
              )}`
            : '-'}
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Multa</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {order?.fine_mode && order.fine_amount
            ? order.fine_mode === 'fixed'
              ? `${currency(plan?.currency)} ${amount(order?.fine_amount)}`
              : `${amount(order?.fine_amount)} %`
            : '-'}
        </dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data do pedido</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {`${date(order.ordered_at)} ${time(order.ordered_at)}`}
        </dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data de criação</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(
          order.created_at
        )} ${time(order.created_at)}`}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data de ativação</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(
          order.activated_at
        )} ${time(order.activated_at)}`}</dd>
      </div>
      <div className="bg-gray-50 p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Data de término</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{`${date(
          order.end_at
        )} ${time(order.end_at)}`}</dd>
      </div>
      <div className="bg-white p-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt className="text-sm font-medium text-gray-500">Cupom</dt>
        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          {order.relationships?.coupon
            ? `Aplicação ${
                translations.duration_mode[
                  order.relationships.coupon.attributes.duration_mode
                ] as string
              }: ${
                order.relationships.coupon.attributes.duration_mode ===
                'repeating'
                  ? `${order.relationships.coupon.attributes.duration} ${
                      translations.coupon_interval[
                        order.relationships.coupon.attributes.interval
                      ]
                    }`
                  : ''
              }` +
              ` ${
                order.relationships.coupon.attributes.mode === 'fixed'
                  ? `${currency(
                      order.relationships.coupon.attributes.currency
                    )} ${amount(order.relationships.coupon.attributes.amount)}`
                  : `${order.relationships.coupon.attributes.amount}%`
              }`
            : '-'}
        </dd>
      </div>
    </dl>
  )
}
