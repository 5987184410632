import { useConfirmationDialog } from 'components/ConfirmationDialog'
import { LoadPage } from 'components/LoadPage'
import { NotFoundPage } from 'components/NotFoundPage'
import { Client, Order, OrderStatus, Plan, Role } from 'models'
import { subscriptionBaseSteps } from 'modules/Orders/helpers/constants'
import { includeStatus, updateSteps } from 'modules/Orders/helpers/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { show } from '../services/read'
import { toast } from 'react-toastify'
import { ProtectedSection } from 'components/ProtectedSection'
import { CancelButton, SimpleButton } from 'components/FormInputs/Button'
import { StepPanel } from 'modules/Orders/components/StepPanel'
import { ReviewTable } from 'modules/Subscriptions/components/ReviewTable'
import { approve, cancel } from '../services/update'
import { isSameDay, setTodayTimeFrom } from 'utils/datetime'
import {
  determineCancelationMinDate,
  getOrderStatusAndDate
} from 'utils/order-status'

export function Show() {
  const { subscriptionId } = useParams() as unknown as {
    subscriptionId: string
  }

  const history = useHistory()

  const [isLoading, setIsLoading] = useState(true)

  const [steps, setSteps] = useState(
    subscriptionBaseSteps.map((step) => Object.assign({}, step))
  )

  const [subscription, setSubscription] = useState<Order>()

  const [currentSubscriptionStatus, setCurrentSubscriptionStatus] = useState(
    OrderStatus.pending
  )

  const [canceledAt, setCanceledAt] = useState(new Date())
  const canceledAtRef = useRef(canceledAt)

  const [approvedAt, setApprovedAt] = useState(new Date())
  const approvedAtRef = useRef(approvedAt)

  useEffect(() => {
    canceledAtRef.current = canceledAt
    approvedAtRef.current = approvedAt
  }, [canceledAt, approvedAt])

  const [plan, setPlan] = useState<Partial<Plan>>()
  const [client, setClient] = useState<Partial<Client>>()

  const [showConfirmationDialog] = useConfirmationDialog()

  const updateSubscriptionStatus = useCallback(
    (currentStatus: OrderStatus) => {
      setCurrentSubscriptionStatus(currentStatus)

      if (currentStatus === 'canceled') {
        setSteps(subscriptionBaseSteps)
      } else {
        setSteps([...updateSteps(steps, currentStatus)])
      }

      return currentStatus
    },
    [steps]
  )

  useEffect(() => {
    if (!subscriptionId) return

    const fetchSubscription = async () => {
      try {
        const subscription = await show(subscriptionId, {
          includes: {
            client: ['id', 'account_name'],
            plan: ['id', 'name', 'nature', 'logistics', 'currency', 'amount'],
            coupon: ['*']
          }
        })

        setSubscription(subscription as Order)

        setPlan({
          id: subscription?.relationships?.plan?.id,
          ...(subscription?.relationships?.plan?.attributes ?? {})
        })

        setClient({
          id: subscription?.relationships?.client?.id,
          ...(subscription?.relationships?.client?.attributes ?? {})
        })

        const subscriptionStatus = subscription.current_status
        updateSubscriptionStatus(subscriptionStatus as OrderStatus)

        setIsLoading(false)
      } catch (err: any) {
        history.push('/subscriptions')
        toast.error(
          err.suggestedMessage ?? 'Falha ao buscar os dados da assinatura'
        )
      }
    }
    fetchSubscription()
  }, [subscriptionId])

  const onCancelConfirmation = useCallback(async () => {
    try {
      const cancelDate = canceledAtRef.current
      if (isSameDay(new Date(), cancelDate)) {
        await cancel(subscriptionId)
      } else {
        await cancel(subscriptionId, cancelDate)
      }
      const data = await show(subscriptionId, {
        attributes: ['id', 'current_status']
      })
      updateSubscriptionStatus(data?.current_status as OrderStatus)
      toast.success('Assinatura cancelada com sucesso!')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível cancelar a assinatura!'
      )
    }
  }, [subscriptionId, updateSubscriptionStatus])

  const onCancelRequest = useCallback(() => {
    showAndUpdateConfirmationDialog(cancelOrderModalAttributes, canceledAt)
  }, [
    currentSubscriptionStatus,
    onCancelConfirmation,
    plan?.fiscal_data,
    showConfirmationDialog
  ])

  const onApprovalConfirmation = useCallback(async () => {
    const approvedAt = approvedAtRef.current
    try {
      await approve(subscriptionId, approvedAt)
      const data = await show(subscriptionId, {
        attributes: ['id', 'current_status']
      })
      updateSubscriptionStatus(data?.current_status as OrderStatus)
      toast.success('Assinatura aprovada com sucesso!')
    } catch (err: any) {
      toast.error(
        err.suggestedMessage ?? 'Não foi possível aprovar a assinatura!'
      )
    }
  }, [subscriptionId, updateSubscriptionStatus])

  const onApprovalRequest = useCallback(() => {
    showAndUpdateConfirmationDialog(
      approveOrderModalAttributes,
      approvedAt,
      'status_update'
    )
  }, [onApprovalConfirmation, showConfirmationDialog, approvedAt])

  const onCanceledAtChange = useCallback((value: string) => {
    showAndUpdateConfirmationDialog(cancelOrderModalAttributes, value)

    setCanceledAt(new Date(value))
  }, [])

  const onApprovedAtChange = useCallback(
    (value: string) => {
      showAndUpdateConfirmationDialog(
        approveOrderModalAttributes,
        value,
        'status_update'
      )

      setApprovedAt(new Date(value))
    },
    [subscription]
  )

  function showAndUpdateConfirmationDialog(
    attributes: Record<string, any>,
    date: Date | string,
    operationType?: string
  ) {
    const orderedAt = new Date(subscription?.ordered_at as string)
    const deliveryDate = subscription?.activated_at
      ? new Date(subscription.activated_at)
      : undefined
    const now = new Date()

    let minDate
    const maxDate = now

    if (operationType && operationType === 'status_update') {
      minDate = new Date(
        getOrderStatusAndDate({
          id: subscription?.id as number,
          type: 'orders',
          attributes: { ...subscription }
        }).date as Date
      )
    } else {
      minDate = determineCancelationMinDate(deliveryDate, orderedAt as Date)
    }

    showConfirmationDialog({
      title: attributes.title,
      message: attributes.message,
      onConfirm: attributes.onConfirm,
      datePicker: true,
      datePickerAttributes: {
        minDate: minDate as Date,
        maxDate: maxDate as Date
      },
      date: new Date(date),
      onDateChange: attributes.onDateChange
    })
  }

  const cancelOrderModalAttributes = {
    title: 'Cancelar pedido',
    message: 'Tem certeza que deseja cancelar este pedido?',
    onConfirm: onCancelConfirmation,
    onDateChange: onCanceledAtChange
  }

  const approveOrderModalAttributes = {
    title: 'Aprovar pedido',
    message: 'Tem certeza que deseja aprovar este pedido?',
    onConfirm: onApprovalConfirmation,
    onDateChange: onApprovedAtChange
  }

  useEffect(() => {
    setApprovedAt(
      setTodayTimeFrom(
        new Date(
          getOrderStatusAndDate({
            id: subscription?.id as number,
            type: 'orders',
            attributes: { ...subscription }
          }).date!
        )
      )
    )
  }, [subscription])

  return isLoading ? (
    <LoadPage />
  ) : !subscription ? (
    <NotFoundPage />
  ) : (
    <div className="overflow-hidden px-4">
      <div className="flex flex-row justify-between py-4 m-4 animate-fade-in-down">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Assinatura: {subscription.token}{' '}
          {currentSubscriptionStatus === 'canceled' && (
            <span className="px-2 mx-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
              Cancelada
            </span>
          )}
        </h3>

        <div className="gap-x-2 flex flex-row justify-between">
          {includeStatus(currentSubscriptionStatus, [OrderStatus.pending]) && (
            <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
              <SimpleButton onClick={onApprovalRequest}>Aprovar</SimpleButton>
            </ProtectedSection>
          )}
        </div>
      </div>

      <StepPanel steps={steps} />

      <ReviewTable order={subscription} client={client} plan={plan} />

      {currentSubscriptionStatus !== OrderStatus.canceled && (
        <div className="py-6 gap-x-2 flex flex-row justify-between animate-fade-in-down">
          <ProtectedSection roles={[Role.MANAGER, Role.FINANCE]}>
            <div className="ml-3 text-left text-md">
              <label className="font-medium text-gray-700">
                Cancelar assinatura
              </label>

              <div className="">
                <CancelButton
                  label="Cancelar"
                  onClick={onCancelRequest}
                ></CancelButton>
              </div>
            </div>
          </ProtectedSection>
        </div>
      )}
    </div>
  )
}
